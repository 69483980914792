















































import { Vue } from 'vue-property-decorator';
import { healthdataService } from '@/_services';
import EventBus from '@/variables'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import {  DoctorListItem } from '@/types';
import DateView from '@/components/DateView.vue';

Vue.use(BootstrapVueIcons);
export default Vue.extend({
  name: "App",
  data() {
    return {
      tabellenNr: 0,
      today: new Date(),
      eventHub: EventBus,
      selectedRoom: "",
      numberOfDays: 10,
      daysBeforeNow: 3,
      maxReportNumber: 0,
      customers: [] as DoctorListItem[],
      fields: [
        { key: "lastName", label: "Nachname"}, 
        { key: "firstName", label: "Vorname" },
        { key: "artistName", label: "Alias"}, 
        { key: "firstDay", label: "Check In", sortable: "true", },
        { key: "abstrich_date", label: "Abstrich", sortable: "true", },
        { key: "abstrich_days", label: "Tage", sortable: "true" },
        { key: "blut_date", label: "Blut", sortable: "true"},
        { key: "blut_days", label: "Tage", sortable: "true" },
        { key: "roentgen_date", label: "TBC", sortable: "true" },
        { key: "roentgen_days", label: "Tage", sortable: "true" },
        
      ],
    };
  },
  created():void {
    this.loadReports();
  },
  methods: {
    loadReports() {
      healthdataService.getDoctorsList().then((items: DoctorListItem[]) => {
        console.log("customerlist received");
        this.customers = items;
        this.tabellenNr++;
      });
    },
    getStyleOf(numDays: number) {
      console.log("getting style of "+ numDays);
      if (numDays<15) {
        console.log("RED");
        return "color: red; font-weight: bigger;"
      } else if (numDays<30) {
        return "color:orange; font-weight: bigger;"
      } else {
        return "color:green; font-weight: bigger;"
      }
    },
    reloadAllData() {
      this.loadReports();
    },
  },
  components: {
    // EditInvoice,
    DateView
  }
  
});
